import React from "react"

const Audyt = () => {
  /*
    this component is created only to avoid 404 errors in console
    while fetching page-data.json by default gatsby mechanism
    while accesing url created dynamically via gatsby-node.js
  */

  return <></>
}

export default Audyt
